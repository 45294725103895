'use client';

import { useState, FormEvent, useRef } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import Modal, { ModalSize } from '@/src/components/Modal';
import Input from '@/src/components/ui/Input2';

interface HeaderSearchProps {
  darkMode: boolean;
}

export const HeaderSearch = ({ darkMode }: HeaderSearchProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  function search() {
    if (!value) {
      return;
    }
    router.push(`/discover#!?search=${value}`);
  }

  function onFormSubmit(e: FormEvent) {
    e.preventDefault();
    search();
  }

  return (
    <>
      {pathname === '/' || pathname === '/v4' ? (
        <div className="hidden sm:flex">
          <button
            className="appearance-none"
            onClick={() => setIsModalOpen(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ stroke: darkMode ? 'white' : 'black' }}
              className="cursor-pointer"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
          </button>
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            modalSize={ModalSize.md}
            modalContentClasses="py-4"
            modalCloseButtonClasses="bg-white -top-8 right-0"
            closeOnOutsideClick={true}
          >
            <form onSubmit={(e) => onFormSubmit(e)} id="discoverSearchForm">
              <Input
                label="Search for"
                type="text"
                placeholder="I want to support..."
                onChange={(e) => setValue(e.target.value)}
                required
              />
              <button type="submit" className="absolute right-8 top-14">
                <img src="https://launchgood.s3.amazonaws.com/static/icons/search-icon.svg" />
              </button>
            </form>
          </Modal>
        </div>
      ) : null}
    </>
  );
};
