'use client';
import { mergeClasses } from '@/src/lib';
import { InputHTMLAttributes, FocusEvent } from 'react';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  className?: string;
  labelClassName?: string;
  onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => void;
  helperText?: string;
};

export default function Input({
  label,
  className,
  labelClassName,
  onBlur,
  required,
  helperText,
  ...props
}: InputProps) {
  return (
    <label className={`${mergeClasses('flex flex-col gap-2', labelClassName)}`}>
      <div className="flex justify-between">
        <p className="text-neutral-600 text-sm font-medium">{label}</p>
        {!required && <p className="text-neutral-600 text-sm">Optional</p>}
      </div>
      <input
        className={`${mergeClasses(
          'border border-neutral-300 rounded-md px-4 py-2 w-full shadow-sm text-neutral-900 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-transparent',
          className,
        )}`}
        onBlur={onBlur}
        {...props}
      />
      {helperText && <p className="text-neutral-600 text-xs">{helperText}</p>}
    </label>
  );
}
