import { request } from '@/src/lib/api/v3/client/core/request';
import { OpenAPI } from '@/src/lib/api/v3/client/core/OpenAPI';

export class DonationRecieptService {
  public static sendEmailRequest = async (donationId: number) => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/user/resendReceipt',
      body: { id: donationId },
    });
  };
}
