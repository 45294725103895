/* eslint-disable @typescript-eslint/no-unused-vars */

import { request } from '@/src/lib/api/v3/client/core/request';
import { OpenAPI } from '@/src/lib/api/v3/client/core/OpenAPI';
import {
  CampaignAdminBarInfo,
  CampaignContributionsResponse,
  CampaignDonationProps,
  CampaignViewProps,
  DonorsProps,
  GivingLevelsProps,
  InstagramShareImages,
  ProjectStatePayload,
  RequirementsCheck,
  ShareLinks,
  TimelineProps,
  V3ApiReturnProps,
} from '@/src/types';
import {
  CampaignAggregate,
  CampaignResponse,
  campaignQueryParams,
} from '@/src/app/(views)/fundraising/dashboard/components/Campaign.interface';
import config from '@/src/config';
import { general_api_response_body } from '../../api/v3/client';

const campaignSearchApiUrl = `${config().lg3OpenSearchUrl}/cards/campaign`;

export class CampaignService {
  public static getCampaignDetails = async (
    slug: string,
  ): Promise<
    general_api_response_body & {
      result?: CampaignViewProps;
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/view',
      body: { slug },
    });
  };

  public static getDonations = async (
    id: number,
  ): Promise<
    general_api_response_body & {
      result?: CampaignDonationProps;
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/statsPanel',
      body: { id },
    });
  };

  public static getTimeline = async (
    slug: string,
  ): Promise<
    general_api_response_body & {
      result?: TimelineProps;
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/timeline',
      body: { slug },
    });
  };

  public static getRewards = async (
    id: number,
  ): Promise<
    general_api_response_body & {
      result?: GivingLevelsProps[];
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/rewards',
      body: { id },
    });
  };

  public static getDonors = async (
    id: number,
    lastId = 0,
  ): Promise<
    general_api_response_body & {
      result?: DonorsProps;
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/donors',
      body: { id, lastId: lastId },
    });
  };

  public static getShareData = async (id: number): Promise<ShareLinks> => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/shareInfo',
      body: { id },
    });
  };

  public static getLiveCampaigns = async (
    accountId: number,
    limit?: number,
  ): Promise<CampaignResponse> => {
    const token = localStorage.getItem('token');
    const res = await fetch(
      `${campaignSearchApiUrl}/by-account-id/${accountId}?campaign_status=active&sort=end_date&limit=${
        limit || 3
      }`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await res.json();
    return data;
  };

  public static getCampaignsByAccount = async (
    accountId: number,
    queryParam?: campaignQueryParams,
  ): Promise<CampaignResponse> => {
    let queryParamsString = '';
    if (queryParam) {
      Object.keys(queryParam).forEach((key, index) => {
        const separator = index === 0 ? '?' : '&';
        if (
          queryParam.hasOwnProperty(key) &&
          queryParam[key as keyof campaignQueryParams]
        ) {
          queryParamsString += `${separator}${key}=${
            queryParam[key as keyof campaignQueryParams]
          }`;
        }
      });
    }
    const token = localStorage.getItem('token');

    const res = await fetch(
      `${campaignSearchApiUrl}/by-account-id/${accountId}${queryParamsString}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await res.json();
    return data;
  };

  public static getCampaignsAggregateByAccount = async (
    accountId: number,
  ): Promise<CampaignAggregate> => {
    const token = localStorage.getItem('token');
    const res = await fetch(
      `${campaignSearchApiUrl}/by-account-id/${accountId}?aggregate_statuses=true&limit=0`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await res.json();
    return data;
  };

  public static getCampaignsByIds = async (
    campaignIds: number[],
    limit = 100,
  ): Promise<CampaignResponse> => {
    // @todo: change it from openAPi generated from docs
    const res = await fetch(
      `${
        config().lg3OpenSearchUrl
      }/cards/campaigns/by-id-list?limit=${limit}&id_list=${campaignIds.join(
        ',',
      )}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
      },
    );
    const data = await res.json();
    return data;
  };

  public static getInstagramShareImages = async (
    slug: string,
  ): Promise<
    general_api_response_body & {
      result: InstagramShareImages;
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/instagramShare',
      body: { slug },
    });
  };

  public static requirementsCheck = async (
    id: number,
  ): Promise<general_api_response_body & { result: RequirementsCheck }> => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/requirementsCheck',
      body: { id },
    });
  };

  public static changeProjectState = async (
    payload: ProjectStatePayload,
  ): Promise<general_api_response_body & { result: CampaignAdminBarInfo }> => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/changeProjectState',
      body: { ...payload },
    });
  };

  public static getCampaignContributions = async (payload: {
    projectID: number;
  }): Promise<
    general_api_response_body & { result: CampaignContributionsResponse }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/user/donationInfo',
      body: payload,
    });
  };
}
