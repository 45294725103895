'use client';

import { useState, useEffect } from 'react';
import NavigationMenu from './NavigationMenu';
import { buildLink } from '@/src/lib/links';
import { BuilderStructuredDataItem, HeaderNavItem } from './interfaces';
import { MenuItemsContext } from '@/src/lib/context';
import Link from 'next/link';
import config from '@/src/config';
import { builder } from '@builder.io/sdk';
import { useAuthHook } from '@/src/lib/store/auth.store';
import { HeaderSearch } from '../HeaderSearch';

type SitewideHeaderProps = {
  darkMode: boolean;
  logoStyle: LogoStyle;
};
type LogoStyle = 'white' | 'black';

builder.init(config().buiderIoApiKey);

export default function SitewideHeader({
  darkMode,
  logoStyle,
}: SitewideHeaderProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<
    BuilderStructuredDataItem<HeaderNavItem>[]
  >([]);
  const [username, setUsername] = useState(''); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [userAvatar, setUserAvatar] = useState('');

  const { user } = useAuthHook();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    const freshDeskChat = document.getElementById('fc_frame');
    if (freshDeskChat) {
      freshDeskChat.hidden = !menuOpen;
    }
  };

  useEffect(() => {
    if (!user) return;

    const { 'user-thumbnail-url': thumbnailUrl, username } = user;

    setUsername(username);
    setUserAvatar(thumbnailUrl);
  }, [user]);

  useEffect(() => {
    if (menuItems.length > 0) return;
    builder
      .getAll('header-nav-items', {
        prerender: false,
      })
      .then((items) => {
        setMenuItems(items as BuilderStructuredDataItem<HeaderNavItem>[]);
      });
  }, [menuItems]);

  return (
    <>
      <MenuItemsContext.Provider value={{ menuItems, toggleMenu }}>
        <section
          className={`relative w-full z-30 ${
            menuOpen && 'bg-white sm:bg-inherit fixed sm:relative'
          }`}
        >
          <div
            className={`py-3 px-4 sm:pt-9 sm:pb-5 sm:px-6 sm:mx-[5%] box-border`}
          >
            <div className="w-full flex justify-between items-center">
              <div className="sm:flex flex-1 hidden">
                <a
                  href={`${buildLink('startCampaign')}`}
                  target="_blank"
                  style={{
                    borderColor: darkMode ? 'white' : 'black',
                    color: darkMode ? 'white' : 'black',
                  }}
                  className="relative bg-transparent max-w-[210px] px-4 md:px-7 py-[10px] mr-auto border font-semibold tracking-wider border-solid rounded-md text-xs md:text-sm transition-all hover:bg-[#7ddba3] duration-500"
                >
                  START A CAMPAIGN
                </a>
              </div>
              <Link
                href={`/v4`}
                prefetch={false}
                className="hidden sm:flex flex-1 justify-center"
              >
                {logoStyle === 'white' && (
                  <img
                    src="https://launchgood.s3.amazonaws.com/static/logos/launchgood-white.svg"
                    width="188px"
                    alt="LaunchGood"
                  />
                )}
                {logoStyle === 'black' && (
                  <img
                    src="https://launchgood.s3.amazonaws.com/static/logos/launchgood-black.svg"
                    width="188px"
                    alt="LaunchGood"
                  />
                )}
              </Link>
              <Link href={`/v4`} prefetch={false} className="sm:hidden">
                {logoStyle === 'white' && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="356"
                    height="42"
                    viewBox="0 0 356 42"
                    fill={menuOpen ? 'black' : 'white'}
                    className={`w-[136px] ${menuOpen ? 'fill-black-500' : ''}`}
                  >
                    <path
                      d="M228.449 0C235.754 0 244.436 2.86183 244.436 8.01084V12.6876C244.436 13.1997 244.018 13.615 243.504 13.615H238.528C238.013 13.615 237.595 13.1997 237.595 12.6876V10.6394C237.595 7.83446 233.168 6.40639 228.798 6.40639C220.116 6.40639 214.705 11.8968 214.705 20.5391C214.705 29.2327 220.11 35.5879 229.141 35.5879C232.419 35.5879 238.516 34.7288 238.516 31.3549V27.0081H232.03C231.515 27.0081 231.098 26.5928 231.098 26.0807V22.3313C231.098 21.8193 231.515 21.404 232.03 21.404H245.363V33.6478C245.363 39.8266 234.432 42 228.398 42C215.86 42 206.886 32.9594 206.886 20.8293C206.88 8.75616 215.74 0 228.449 0Z"
                      fill="inherit"
                    />
                    <path
                      d="M2.47093 7.03783C3.16874 7.03783 3.73499 7.60109 3.73499 8.29521V37.4198C3.73499 39.5705 5.48524 41.3115 7.6473 41.3115H25.6473C27.8094 41.3115 29.5596 39.5705 29.5596 37.4198V32.1684C29.5596 31.6564 29.1421 31.241 28.6273 31.241H23.6511C23.1364 31.241 22.7188 31.6564 22.7188 32.1684V33.6989C22.7188 34.393 22.1526 34.9563 21.4548 34.9563H12.5377C11.8399 34.9563 11.2736 34.393 11.2736 33.6989V4.57996C11.2736 1.88882 10.124 0.68833 7.36132 0.68833H0.932321C0.417544 0.68833 0 1.10366 0 1.61572V6.11044C0 6.62249 0.417544 7.03783 0.932321 7.03783H2.47093Z"
                      fill="inherit"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M53.1022 23.0026H52.1813C47.4053 23.0026 34.7532 23.7422 34.7475 32.8967C34.7475 38.7341 39.5235 41.9942 44.7571 41.9942C51.3748 41.9942 53.617 36.8452 53.617 36.8452H53.7314C53.7314 36.8452 53.6742 37.3572 53.6742 38.1026C53.6742 39.8208 54.7094 41.3058 57.4149 41.3058H63.9697V35.1838H61.6704C60.9726 35.1838 60.4063 34.6206 60.4063 33.9265V23.0538C60.4063 16.5336 57.5293 11.4415 47.7542 11.4415C44.7056 11.4415 37.0011 12.0161 37.0011 17.4496V19.6116C37.0011 20.1237 37.4186 20.539 37.9334 20.539H42.8524C43.3672 20.539 43.7847 20.1237 43.7847 19.6116V18.9972C43.7847 17.3927 46.3758 17.0514 47.697 17.0514C51.432 17.0514 53.1022 18.5989 53.1022 22.775V23.0026ZM53.2166 28.6637C53.2166 32.2082 50.4539 36.3274 46.5416 36.3274C43.5502 36.3274 42.1146 34.5011 42.1203 32.5496C42.1203 28.4873 48.1546 27.9752 51.9525 27.9752H53.2166V28.6637Z"
                      fill="inherit"
                    />
                    <path
                      d="M68.7701 19.5664C68.7701 18.7073 68.3126 18.3091 67.5061 18.3091H66.139C65.6243 18.3091 65.2067 17.8937 65.2067 17.3817V13.0633C65.2067 12.5513 65.6243 12.1359 66.139 12.1359H72.1619C74.8674 12.1359 76.0171 13.3364 76.0171 15.9707V29.415C76.0171 32.9596 76.9379 35.3662 80.5585 35.3662C85.8493 35.3662 88.7835 30.735 88.7835 25.5234V12.1302H96.0876V33.9268C96.0876 34.6209 96.6539 35.1842 97.3517 35.1842H98.7187C99.2335 35.1842 99.651 35.5995 99.651 36.1116V40.373C99.651 40.8851 99.2335 41.3004 98.7187 41.3004H92.9246C90.3965 41.3004 89.0695 40.0999 89.0695 38.0403V37.2381C89.0695 36.5496 89.1267 35.9238 89.1267 35.9238H89.0123C87.6339 38.9563 83.9503 41.9888 78.8884 41.9888C72.6195 41.9888 68.7644 38.8425 68.7644 30.8886V19.5664H68.7701Z"
                      fill="inherit"
                    />
                    <path
                      d="M103.255 18.3088C104.062 18.3088 104.519 18.7071 104.519 19.5662H104.514V41.3058H111.761V27.9241C111.761 26.5472 111.938 25.233 112.338 24.0325C113.374 20.5391 116.308 18.0812 120.22 18.0812C123.898 18.0812 124.819 20.4879 124.819 24.0325V37.4142C124.819 39.5649 126.569 41.3058 128.731 41.3058H135.692V35.1896H133.393C132.695 35.1896 132.129 34.6264 132.129 33.9322V22.5475C132.129 14.8211 128.388 11.4473 121.89 11.4473C115.965 11.4473 112.802 15.0544 111.538 17.5123H111.423C111.423 17.5123 111.538 16.8864 111.538 16.2549V15.3958C111.538 13.3362 110.274 12.1357 107.683 12.1357H104.176C103.662 12.1357 103.244 12.551 103.244 13.0631V17.3814C103.244 17.8935 103.662 18.3088 104.176 18.3088H103.255Z"
                      fill="inherit"
                    />
                    <path
                      d="M155.031 11.4415C158.829 11.4415 165.669 12.9834 165.669 18.0186V20.522C165.669 21.034 165.252 21.4494 164.737 21.4494H160.161C159.647 21.4494 159.229 21.034 159.229 20.522V19.8506C159.229 18.2462 156.815 17.5634 155.031 17.5634C149.969 17.5634 146.348 21.3981 146.348 26.6609C146.348 32.6122 150.775 35.6447 155.494 35.6447C159.097 35.6447 162.014 33.5965 163.364 32.4529C163.805 32.0774 164.474 32.1855 164.771 32.6805L166.859 36.134C167.082 36.5038 167.025 36.9704 166.716 37.2719C165.349 38.6146 161.271 41.9999 154.859 41.9999C145.198 41.9999 138.987 35.1895 138.987 26.7235C138.987 18.4794 144.964 11.4415 155.031 11.4415Z"
                      fill="inherit"
                    />
                    <path
                      d="M171.612 6.86718C172.419 6.86718 172.876 7.26545 172.876 8.12457V40.3841C172.876 40.8962 173.294 41.3115 173.809 41.3115H179.248C179.763 41.3115 180.18 40.8962 180.18 40.3841V27.9241C180.18 26.5472 180.295 25.2898 180.638 24.1462C181.73 20.596 184.722 18.0812 188.577 18.0812C192.255 18.0812 193.233 20.4879 193.233 24.0324V37.4142C193.233 40.1622 194.382 41.3058 197.088 41.3058H203.122C203.637 41.3058 204.055 40.8905 204.055 40.3784V36.117C204.055 35.6049 203.637 35.1896 203.122 35.1896H201.755C201.057 35.1896 200.491 34.6263 200.491 33.9322V22.5475C200.491 14.8211 196.808 11.4472 190.253 11.4472C185.191 11.4472 181.565 14.4229 180.186 17.2278H180.072C180.072 17.2278 180.186 16.198 180.186 14.7699V4.52879C180.186 1.89455 178.979 0.694061 176.274 0.694061H170.245C169.73 0.694061 169.313 1.1094 169.313 1.62145V5.93979C169.313 6.45185 169.73 6.86718 170.245 6.86718H171.612Z"
                      fill="inherit"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M282.987 26.7178C282.987 17.791 275.855 11.4415 266.88 11.4415C257.969 11.4415 250.837 17.7967 250.837 26.7178C250.837 35.7016 257.963 41.9942 266.88 41.9942C275.855 41.9942 282.987 35.7016 282.987 26.7178ZM275.626 26.7178C275.626 32.1513 271.656 35.8154 266.88 35.8154C262.167 35.8154 258.198 32.1513 258.198 26.7178C258.198 21.3413 262.162 17.6203 266.88 17.6203C271.656 17.6203 275.626 21.3413 275.626 26.7178Z"
                      fill="inherit"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M303.132 11.4415C312.106 11.4415 319.239 17.791 319.239 26.7178C319.239 35.7016 312.106 41.9942 303.132 41.9942C294.215 41.9942 287.088 35.7016 287.088 26.7178C287.083 17.7967 294.215 11.4415 303.132 11.4415ZM303.132 35.8154C307.908 35.8154 311.878 32.1513 311.878 26.7178C311.878 21.3413 307.908 17.6203 303.132 17.6203C298.413 17.6203 294.45 21.3413 294.45 26.7178C294.45 32.1513 298.413 35.8154 303.132 35.8154Z"
                      fill="inherit"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M345.132 14.9861C345.132 14.9861 343.062 11.4415 336.679 11.4415C328.911 11.4415 323.334 17.4497 323.334 26.7122C323.334 35.7528 328.454 41.9885 336.393 41.9885C343.125 41.9885 345.539 37.2378 345.539 37.2378H345.653V38.3245C345.653 40.0427 346.625 41.3001 349.274 41.3001H355.068C355.582 41.3001 356 40.8848 356 40.3727V36.1113C356 35.5992 355.582 35.1839 355.068 35.1839H353.758C353.06 35.1839 352.494 34.6206 352.494 33.9265V4.57998C352.494 2.42935 350.744 0.688354 348.581 0.688354H342.559C342.044 0.688354 341.626 1.10369 341.626 1.61575V5.93409C341.626 6.44614 342.044 6.86148 342.559 6.86148H343.926C344.732 6.86148 345.19 7.25974 345.19 8.11886V13.2679C345.19 14.2408 345.247 14.9861 345.247 14.9861H345.132ZM338.114 17.6772C343.005 17.6772 345.361 22.1435 345.361 26.661C345.361 33.1243 341.798 35.8723 338 35.8723C333.687 35.8723 330.753 32.2652 330.753 26.7179C330.753 20.9373 334.088 17.6772 338.114 17.6772Z"
                      fill="inherit"
                    />
                  </svg>
                )}
                {logoStyle === 'black' && (
                  <img
                    src="https://launchgood.s3.amazonaws.com/static/logos/launchgood-black.svg"
                    width="136px"
                    alt="LaunchGood"
                  />
                )}
              </Link>
              <div className="flex relative gap-4 md:gap-6 flex-1 justify-end ml-auto">
                {/* LEAF ICON */}
                {/* <Link
                  prefetch={false}
                  href={`${config().lg3BaseUrl}${dhcHomeUrl}`}
                  data-tracking="home-leaf"
                >
                  <SingleLeafSvg />
                </Link> */}
                {/* SEARCH ICON */}
                <HeaderSearch darkMode={darkMode} />

                {/* MENU ICON */}
                <section
                  className="flex items-center gap-4 md:gap-6 cursor-pointer group"
                  onClick={toggleMenu}
                >
                  {user && (
                    <img
                      src={userAvatar}
                      alt="User avatar"
                      className="rounded-full w-8 h-8"
                    />
                  )}
                  <button className="appearance-none">
                    {!menuOpen && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke={darkMode ? 'white' : 'black'}
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="group-hover:!stroke-primary transition-all duration-200 hidden sm:flex"
                        >
                          <line x1="3" y1="12" x2="21" y2="12"></line>
                          <line x1="3" y1="6" x2="21" y2="6"></line>
                          <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="15"
                          viewBox="0 0 24 15"
                          fill="none"
                          className="group-hover:!stroke-primary transition-all duration-200 sm:hidden"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.2026 4.3588C9.9231 2.59054 8.24702 1.38251 6.45898 1.66059C4.67093 1.93867 3.44802 3.59756 3.72752 5.36582C4.00702 7.13408 5.68309 8.34211 7.47114 8.06403C9.25918 7.78595 10.4821 6.12707 10.2026 4.3588ZM6.20594 0.0597282C8.88801 -0.35739 11.4021 1.45466 11.8214 4.10705C12.2406 6.75944 10.4062 9.24777 7.72418 9.66489C6.67535 9.82801 5.65221 9.65024 4.76998 9.21463L1.48106 13.6761C1.21438 14.0378 0.701906 14.1175 0.336413 13.8541C-0.0290816 13.5906 -0.10919 13.0838 0.157486 12.7221L3.44641 8.26067C2.75699 7.56351 2.2727 6.65479 2.10875 5.61757C1.6895 2.96518 3.52387 0.476847 6.20594 0.0597282ZM15 2.00523e-09C14.4477 2.00523e-09 14 0.447715 14 1C14 1.55228 14.4477 2 15 2H26C26.5523 2 27 1.55228 27 1C27 0.447715 26.5523 2.00523e-09 26 2.00523e-09H15ZM16 6C15.4477 6 15 6.44772 15 7C15 7.55228 15.4477 8 16 8H26C26.5523 8 27 7.55228 27 7C27 6.44772 26.5523 6 26 6H16ZM10 13C10 12.4477 10.4477 12 11 12H26C26.5523 12 27 12.4477 27 13C27 13.5523 26.5523 14 26 14H11C10.4477 14 10 13.5523 10 13Z"
                            fill="#4E5562"
                          ></path>
                        </svg>
                      </>
                    )}

                    {menuOpen && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke={
                          darkMode && screen.width > 768 ? 'white' : 'black'
                        }
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className={`group-hover:!stroke-primary transition-all duration-200`}
                      >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                    )}
                  </button>
                </section>
                <span className={`contents ${!menuOpen ? 'hidden' : ''}`}>
                  <NavigationMenu />
                </span>
              </div>
            </div>
          </div>
        </section>
      </MenuItemsContext.Provider>
    </>
  );
}
