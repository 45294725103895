import { request } from '@/src/lib/api/v3/client/core/request';
import { OpenAPI } from '@/src/lib/api/v3/client/core/OpenAPI';
import {
  Currency,
  GetUserByTokenResponse,
  Preference,
  PreferencePostRequest,
  SetPasswordResponse,
} from '@/src/types';
import { general_api_response_body } from '../../api';

export class UserPreferencesService {
  public static getUserPreferences = async (
    userId: number,
  ): Promise<general_api_response_body & { result: Preference[] }> => {
    return await request(OpenAPI, {
      method: 'GET',
      url: '/api/user/properties',
      query: { userId },
    });
  };

  public static setUserPreference = async (
    preference: PreferencePostRequest,
  ): Promise<general_api_response_body & { result: Preference }> => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/user/properties',
      body: preference,
    });
  };

  public static setPreferredCurrency = async (
    currencyCode: Currency,
  ): Promise<general_api_response_body> => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/user/setPreferredCurrency',
      body: { currencyCode },
    });
  };

  public static setPassword = async (
    code: string,
    password: string,
  ): Promise<general_api_response_body & { result: SetPasswordResponse }> => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/user/setPassword',
      body: { code, password },
    });
  };

  public static getByToken = async (
    code: string,
  ): Promise<
    general_api_response_body & { result: GetUserByTokenResponse }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/user/getByCode',
      body: { code },
    });
  };
}
