import { mergeClasses } from '@/src/lib/utils';
import { useEffect, useRef } from 'react';
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  modalSize?: ModalSize;
  modalContentClasses?: string;
  modalCloseButtonClasses?: string;
  closeOnOutsideClick?: boolean;
}

export enum ModalSize {
  sm = '330px',
  md = '600px',
  lg = '700px',
  xl = '800px',
}

const Modal = ({
  isOpen,
  onClose,
  children,
  modalSize,
  modalContentClasses,
  modalCloseButtonClasses,
  closeOnOutsideClick,
}: ModalProps) => {
  const getModalClass = () => {
    switch (modalSize) {
      case ModalSize.sm:
        return 'w-[330px]';
      case ModalSize.md:
        return 'md:w-[600px] sm:w-[420px] w-full';
      case ModalSize.lg:
        return 'lg:w-[700px] md:w-[600px] sm:w-[500px] w-full';
      case ModalSize.xl:
        return 'lg:w-[800px] md:w-[700px] sm:w-[500px] w-full';
      default:
        return 'w-auto';
    }
  };

  const drawerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (closeOnOutsideClick) {
      const handleOutsideClick = (event: MouseEvent) => {
        if (
          isOpen &&
          drawerRef.current &&
          !drawerRef.current.contains(event.target as Node)
        ) {
          onClose();
        }
      };

      if (isOpen) {
        document.addEventListener('mousedown', handleOutsideClick);
      }

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  }, [isOpen, onClose]);

  return (
    <div
      className={`fixed inset-0 z-50 bg-neutral-900/30  transition-all  ${
        isOpen ? '' : 'hidden'
      }`}
    >
      <div className="flex items-center justify-center min-h-screen">
        <div
          className={mergeClasses([
            'bg-white rounded-lg shadow-md ',
            getModalClass(),
          ])}
          ref={drawerRef}
        >
          <div className="relative">
            <button
              onClick={onClose}
              className={mergeClasses([
                'absolute top-6 right-6 rounded-full shadow p-0.5',
                modalCloseButtonClasses,
              ])}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6L6 18"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <div className={mergeClasses(['p-4', modalContentClasses])}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
