import { mergeClasses } from '@/src/lib/utils';
import Image from 'next/image';
import { forwardRef, FocusEvent, InputHTMLAttributes, useState } from 'react';

type InputProps = {
  icon?: string;
  onIconClicked?: () => void;
  addOn?: string;
  isError?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

type InputWithInlineAddOnProps = {
  addOn?: string;
  isError?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { icon, onIconClicked, type, placeholder, className, id, ...props },
  ref,
) {
  const [hasData, setHasData] = useState(false);
  const labelActiveClasses = 'pb-3 pt-5';
  const placeholderActiveClasses = 'text-2xs !top-1';

  function handleBlur(e: FocusEvent) {
    (e.target as HTMLInputElement).value.length > 0
      ? setHasData(true)
      : setHasData(false);
  }

  return (
    <label
      className={mergeClasses(
        className,
        'group flex items-center gap-2 cursor-text focus-within:border-primary focus-within:pb-3 focus-within:pt-5 relative p-4 box-border shadow-inset-grey focus-within:shadow-inset-primary text-gray-600 bg-gray-100 border border-gray-400 rounded-lg transition-all duration-300',
        `${hasData ? labelActiveClasses : ''}`,
      )}
    >
      {icon && (
        <Image
          src={icon}
          width={20}
          height={20}
          alt="icon"
          onClick={onIconClicked}
          className={`${
            onIconClicked ? 'cursor-pointer' : 'pointer-events-none'
          }`}
        />
      )}

      <span
        className={`${icon ? 'left-11' : ''} ${
          hasData ? placeholderActiveClasses : ''
        } absolute top-4 w-[calc(100%-60px)] text-gray-500 group-focus-within:text-2xs group-focus-within:top-1 group-focus-within:text-primary transition-all duration-500 pointer-events-none truncate`}
      >
        {placeholder}
      </span>
      <input
        ref={ref}
        type={type}
        className="w-full appearance-none bg-transparent !outline-none !border-0 p-0 !shadow-transparent !ring-0"
        id={id}
        onBlur={handleBlur}
        {...props}
      />
    </label>
  );
});

export const InputWithoutLabel = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(function InputWithoutLabel({ className, ...props }, ref) {
  return (
    <input
      {...props}
      ref={ref}
      className={`h-full border border-rebuild-gray-300 rounded-lg p-6 text-lg w-full text-center font-bold text-black-200 ${className}`}
    />
  );
});

export const InputWithInlineAddOn = forwardRef<
  HTMLInputElement,
  InputWithInlineAddOnProps
>(function InputWithInlineAddOn(
  { addOn = '', isError = false, className, ...props },
  ref,
) {
  return (
    <div
      className={`bg-gray-100 h-full flex rounded-md border ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset ${
        isError ? 'focus-within:ring-red-600 ' : 'focus-within:ring-indigo-600 '
      }`}
    >
      <span className="flex select-none items-center pl-3 text-lg font-bold">
        {addOn}
      </span>
      <input
        {...props}
        ref={ref}
        className={`flex items-center flex-1 border-0 bg-transparent text-neutral-600 placeholder:text-gray-400 focus:ring-0 text-lg font-bold px-0 pr-10 ${className}`}
      />
    </div>
  );
});
