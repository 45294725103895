import { OpenAPI } from '@/src/lib/api/v3/client';
import { request } from '@/src/lib/api/v3/client/core/request';
import { CampaignCardProps, CampaignCardRequest } from '@/src/types';

export class CampaignCardService {
  public static getCampaigns = async (
    req: CampaignCardRequest,
  ): Promise<CampaignCardProps[]> => {
    return await request(OpenAPI, {
      method: 'GET',
      url: '/api/cards/card',
      query: {
        ...req,
      },
    });
  };
}
