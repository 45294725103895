'use client';
import { Currency, CurrencyCodeToSymbol, CurrencyFlags } from '@/src/types';
import { useContext, useEffect, useState, useRef } from 'react';
import { FxService } from '@/src/lib/services';
import { UserContext } from '@/src/lib/context';
import { useAuthHook } from '@/src/lib/store/auth.store';
import { UserPreferencesService } from '@/src/lib/services/v3';

interface CurrencySelectorDropdown {
  label: string;
  value: string;
}

export default function CurrencySelectorDropdown() {
  const { currency: userSetCurrency, setCurrency } = useContext(UserContext);
  const [options, setOptions] = useState<CurrencySelectorDropdown[]>([]);
  const [defaultOption, setDefaultOption] =
    useState<CurrencySelectorDropdown>();

  const { user } = useAuthHook();

  const currencySelectorBlock = useRef<HTMLDetailsElement>(null);

  useEffect(() => {
    if (options.length > 0) return;
    FxService.getSupportedCurrencies().then((res) => {
      const { result: currencies } = res;
      if (!currencies) return;
      const tempOptions: CurrencySelectorDropdown[] = [];

      currencies.forEach((currency: string) => {
        const currencyCode = currency as Currency;
        const label = `${CurrencyFlags[currencyCode]} ${CurrencyCodeToSymbol[currencyCode]} ${currency}`;
        tempOptions.push({
          label,
          value: currency,
        });
      });

      setOptions(tempOptions);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!options.length) return;
    setDefaultOption(
      options.find((option) => option.value === userSetCurrency),
    );
  }, [options, userSetCurrency]);

  async function onValueChange(value: string) {
    if (user) {
      await UserPreferencesService.setPreferredCurrency(value as Currency);
    }
    localStorage.setItem('userPreferredCurrency', value);
    setCurrency(value as Currency);
    currencySelectorBlock &&
      currencySelectorBlock?.current?.removeAttribute('open');
  }

  return (
    <>
      {options.length > 0 && (
        <details
          className="flex gap-4 items-center text-sm px-4 py-2 cursor-pointer group [&_.icon]:hover:translate-x-2 [&_.arrow]:open:-rotate-180"
          ref={currencySelectorBlock}
        >
          <summary className="flex gap-4 items-center justify-between">
            <div className="flex gap-4 items-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.4 8.00007C14.4 11.5347 11.5346 14.4001 7.99998 14.4001C4.46535 14.4001 1.59998 11.5347 1.59998 8.00007C1.59998 4.46544 4.46535 1.60007 7.99998 1.60007C11.5346 1.60007 14.4 4.46544 14.4 8.00007ZM13.2 8.00007C13.2 10.8719 10.8719 13.2001 7.99998 13.2001C5.12809 13.2001 2.79998 10.8719 2.79998 8.00007C2.79998 6.52538 3.41384 5.19407 4.39998 4.24772V4.60595C4.39998 5.34856 4.69497 6.06075 5.22008 6.58585L6.63429 8.00007L6.39998 8.23438C6.08755 8.5468 6.08756 9.05333 6.39997 9.36575L7.2485 10.2143C7.47355 10.4393 7.59998 10.7445 7.59998 11.0628V11.3529C7.59998 11.6559 7.77118 11.9329 8.0422 12.0684L8.26332 12.179C8.6585 12.3765 9.13904 12.2164 9.33663 11.8212L10.4999 9.49469C10.7309 9.0327 10.6403 8.47473 10.2751 8.1095L9.65762 7.49203C9.44336 7.27776 9.12642 7.20295 8.83896 7.29877L8.53171 7.40118C8.34287 7.46413 8.13648 7.37864 8.04745 7.2006L7.81073 6.72715C7.73665 6.57898 7.76569 6.40004 7.88282 6.28291C7.99995 6.16578 8.17889 6.13674 8.32705 6.21082L8.53663 6.31561C8.64772 6.37115 8.77021 6.40007 8.8944 6.40007H9.04501C9.59105 6.40007 9.97663 5.86511 9.80395 5.34708L9.74955 5.18389C9.69812 5.0296 9.74523 4.85954 9.86871 4.7537L11.0203 3.76663C12.3398 4.70975 13.2 6.25446 13.2 8.00007Z"
                  fill="#A5B1C4"
                />
              </svg>
              <p>{defaultOption?.label}</p>
            </div>
            <svg
              className="arrow transition-transform duration-300"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#D8DFEA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </summary>

          <div className="flex flex-col gap-3 items-start text-sm px-4 py-3 pl-8 group">
            {options.map((option, idx) => (
              <button
                className="flex items-center gap-4"
                key={idx}
                onClick={() => onValueChange(option.value)}
              >
                {defaultOption?.value === option.value ? (
                  <svg
                    className="-ml-8"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3333 4.66675L5.99996 12.0001L2.66663 8.66675"
                      stroke="#A5B1C4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : null}
                {option.label}
              </button>
            ))}
          </div>
        </details>
      )}
    </>
  );
}
